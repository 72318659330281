<template>
  <Row :image-path="imagePath" :labels="labels" :image-classes="imageClasses" row-class="research" title-display="full">
    <template #imageExtra>
      <RadialProgress :target="{val: researchProgressVal, max: research.val}"
                      :always-show-hints="true"
                      :hints="{'bottom-right': 'valueRemaining', 'top-left': 'max', 'bottom-left': 'value'}"/>
    </template>

    <template #buttons>
      <DealButton :deal="researchDeal" :action="performResearch">Research</DealButton>
    </template>
  </Row>
</template>

<script setup>
import {images, utils} from '@/helpers';
import RadialProgress from '@/components/utils/RadialProgress';
import DealButton from '@/components/utils/DealButton';
import Row from '@/components/utils/Row';
import {api} from '@/api';
import {Game} from '@/common';
import {computed} from 'vue';
import {compositions} from '@/compositions';

const props = defineProps(
    {
      research: {type: Object, required: true},
    }
);

const id = computed(() => props.research._id);

const { researchProgressVal, researchLeft } = compositions.research(id.value);

const imagePath = computed(() => images.getOrExtract(`researches/${id.value}.jpg`, props.research.requires));

const researchMutation = computed(() => {
  const val = Math.max(1, Math.min(Game.player.props.rp.val, researchLeft.value));
  return {p1: 'props', p2: 'rp', p3: 'val', val: val};
});

const researchDeal = computed(() => {
  const result = utils.clone(props.research);
  result.costs = [researchMutation.value];
  return result;
});

const labels = computed(() => Game.getLabels('researches', id.value));

const imageClasses = computed(
    () => props.research.type === 'ABILITY' ? ['animation-glowing', '-animation-slow', '-animation-infinite'] : null);

function performResearch() {
  api.post(`researches/${id.value}`);
}
</script>

<style>
.research .row-image {
  width: 110px;
  height: 88px;
}
</style>
