<template>
  <div v-show="isVisible">
    <radial-progress-bar v-if="showRadialBar"
                         :diameter="18" :animate-speed="100" :inner-stroke-width="2" :stroke-width="4"
                         start-color="var(--bar-start)" stop-color="var(--bar-stop)"
                         :total-steps="totalSteps"
                         :completed-steps="currentSteps"/>
    <span v-if="showText" v-text="displayText"/>
  </div>
</template>

<script setup>
import {Game} from '@/common';
import RadialProgressBar from 'vue3-radial-progress';
import {constants, utils} from '@/helpers';
import {computed, onMounted} from 'vue';

const MAX_UPH_TO_SHOW_RADIAL = 1000;

const props = defineProps(
    {
      target: {type: Object},
      display: {type: String, validator(v) { return ['auto', 'radial', 'text', 'off'].includes(v) }, default: 'auto'},
      valueUpdater: {type: Function},
      textUpdater: {type: Function},
    }
);

const isInProgress = computed(() => {
  const t = props.target;
  return t?.uph && t.max && t.val < t.max;
});

const showRadialBar = computed(() => {
  if (!isInProgress.value) {
    return false;
  }
  switch (props.display) {
    case 'radial':
      return true;
    case 'auto':
      return !props.target?.float;
    default:
      return false;
  }
});

const showText = computed(() => {
  if (!isInProgress.value) {
    return false;
  }
  switch (props.display) {
    case 'text':
      return true;
    case 'auto':
      return props.target?.float;
    default:
      return false;
  }
});

const isVisible = computed(() => {
  if (props.display === 'off') {
    return false;
  }
  if (showRadialBar.value) {
    return (props.target?.uph ?? 0) <= MAX_UPH_TO_SHOW_RADIAL;
  }
  return showText.value;
});

const totalSteps = computed(() => {
  const t = props.target;
  return t.float ? t.max : constants.MILLIS_IN_HOUR / t.uph;
});

const currentSteps = computed(() => {
  const t = props.target;
  return t.float ? t.val : Game.now - t.at;
});

const displayText = computed(() => {
  if (!isInProgress.value) {
    return '';
  }
  const t = props.target;
  let millis = (t.max - t.val) / t.uph * constants.MILLIS_IN_HOUR;
  if (!t.float) {
    millis -= Game.now - t.at;
  }
  return utils.millisToTime(millis);
});

onMounted(() => {
  if (props.valueUpdater) {
    utils.watchAndTrigger(() => props.target.val, () => props.valueUpdater(props.target.val));
  }
  if (props.textUpdater) {
    utils.watchAndTrigger(displayText, () => props.textUpdater(displayText.value));
  }
});

</script>
