<template>
  <div v-if="prop" class="property-container" :data-testid="testId">
    <div v-if="icon" class="property-icon">
      <Icon :path="icon" :display="iconDisplay" :title="title"/>
    </div>
    <div v-if="showBar" class="property-bar">
      <ProgressBar :color="color"
                   :title="title"
                   :display-value="computedDisplayValue"
                   :extra="computedExtra"
                   :value="computedValue"
                   :max="computedMax"
                   :min="prop.min"
                   :number-format="numberFormat"
      />
    </div>
    <div class="property-time">
      <Clock :target="prop" :display="timeDisplay" :value-updater="getValueUpdater()" :text-updater="getTextUpdater()"/>
    </div>
  </div>
</template>

<script setup>
import Icon from '@/components/utils/Icon';
import ProgressBar from '@/components/utils/ProgressBar';
import Clock from '@/components/utils/Clock';
import {Game} from '@/common';
import {computed, ref, watch} from 'vue';
import {compositions} from '@/compositions';

const props = defineProps(
    {
      icon: {type: String},
      iconDisplay: {type: String, default: 'info'},
      title: {type: String},
      prop: {type: Object, default() { return {}; }},
      displayValue: undefined,
      extra: undefined,
      color: {type: [String, Array]},
      timeDisplay: {type: String},
      showBar: {type: Boolean, default: true},
      ...compositions.propDefs.numbers,
    }
);

const computedValue = ref(props.prop.val);

const computedDisplayValue = ref(props.displayValue);

watch(() => props.displayValue, newValue => computedDisplayValue.value = newValue);

const computedExtra = computed(() => {
  if (props.extra !== undefined) {
    return props.extra;
  }
  else {
    return props.prop.uph ? [props.prop.uph, '/', Game.commonLabels.units.hours] : null;
  }
});

const isTimed = computed(() => props.prop.to);

const computedMax = computed(() => isTimed.value ? props.prop.duration : props.prop.max);

const testId = computed(() => `Property::${props.icon}`);

function getValueUpdater() {
  return props.showBar ?
         (value) => computedValue.value = value :
         undefined;
}

function getTextUpdater() {
  return props.displayValue === undefined && isTimed.value ?
         (text) => computedDisplayValue.value = text :
         undefined;
}
</script>

<style>
.property-container {
  display: flex;
}

.property-icon {
  margin-top: 4px;
  margin-right: 2px;
}

.property-bar {
  flex: 1;
}

.property-time {
  margin-top: 4px;
  margin-left: 3px;
}
</style>
