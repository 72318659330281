<template>
  <div id="game-holder" class="fullscreen">
    <div v-if="Game.player && Game.gameConf">
      <div v-if="showBgImage" class="game-background fullscreen" :style="`background-image: url('${bgImage}')`"/>
      <video v-if="showBgVideo" :class="videoClasses" autoplay loop muted>
        <source :src="require('@/assets/regions/0.mp4')" type="video/mp4">
      </video>
      <TopPanel/>

      <ControlPanel/>

      <Modal id="player">
        <Player/>
      </Modal>

      <Modal id="missions">
        <Missions/>
      </Modal>

      <Modal id="artifacts">
        <Artifacts/>
      </Modal>

      <Modal v-slot="{extra}" id="targetArtis">
        <Artifacts v-bind="extra"/>
      </Modal>

      <Modal id="market">
        <Commodities/>
      </Modal>

      <Modal id="researches">
        <template #header><ResearchesHeader/></template>
        <Researches/>
      </Modal>

      <Modal id="npcs">
        <template #header><NpcsHeader/></template>
        <Npcs/>
      </Modal>

      <Modal id="colonies">
        <template #header><ColoniesHeader/></template>
        <Colonies/>
      </Modal>

      <Modal id="modules">
        <template #header>
          <ModulesHeader :test-identifier="Game.playerId"/>
        </template>
        <Modules/>
      </Modal>

      <Modal id="ownedModules">
        <template #header="{extra}">
          <ModulesHeader v-bind="extra"/>
        </template>
        <template #default="{extra}">
          <Modules v-bind="extra" />
        </template>
      </Modal>

      <Modal id="regions">
        <template #header><RegionsHeader/></template>
        <Regions/>
      </Modal>

      <Modal id="races">
        <Races/>
      </Modal>

      <Modal v-slot="{extra}" id="assist">
        <Assist :uid="extra"/>
      </Modal>

      <Modal id="settings">
        <UserSettings/>
        <LegalContent/>
      </Modal>

      <Tickers/>
    </div>
  </div>
</template>

<script setup>
import Artifacts from '@/components/Artifacts';
import Assist from '@/components/Assist';
import Colonies from '@/components/Colonies';
import ColoniesHeader from '@/components/ColoniesHeader';
import Commodities from '@/components/Commodities';
import ControlPanel from '@/components/ControlPanel';
import LegalContent from '@/components/utils/LegalContent';
import Missions from '@/components/Missions';
import Modal from '@/components/utils/Modal';
import Modules from '@/components/Modules';
import ModulesHeader from '@/components/ModulesHeader';
import Npcs from '@/components/Npcs';
import NpcsHeader from '@/components/NpcsHeader';
import Player from '@/components/Player';
import Races from '@/components/Races';
import Regions from '@/components/Regions';
import RegionsHeader from '@/components/RegionsHeader';
import Researches from '@/components/Researches';
import ResearchesHeader from '@/components/ResearchesHeader';
import Tickers from '@/components/utils/Tickers';
import TopPanel from '@/components/TopPanel';
import UserSettings from '@/components/UserSettings';
import {api} from '@/api';
import {computed, onMounted, onUnmounted, ref, watch} from 'vue';
import {Game, regionBus} from '@/common';

const regionEffectTimeout = 3900; // must be slightly shorter than the CSS effect duration

const tickId = ref(null);

const previousRegionId = ref(undefined);

const videoClass = ref(undefined);

const regionBusOff = ref(null);

const videoClasses = computed(() => {
  const result = ['game-background-video', 'fullscreen'];
  videoClass.value && result.push(videoClass.value);
  return result;
});

const showBgImage = computed(() => !Game.movementInProgress || previousRegionId.value);

const showBgVideo = computed(() => Game.movementInProgress || videoClass.value);

const bgImage = computed(() => {
  const regionId = previousRegionId.value ?? Game.player.regionId;
  return require(`@/assets/regions/${regionId}.jpg`);
});

onMounted(() => {
  bootGame();
  tickId.value = window.setInterval(updateNow, 250);
  regionBusOff.value = regionBus.on(previous => {
    previousRegionId.value = previous;
    videoClass.value = '-fade-in';
    window.setTimeout(() => previousRegionId.value = undefined, regionEffectTimeout);
  });
});

onUnmounted(() => {
  regionBusOff.value();
  window.clearInterval(tickId.value);
  tickId.value = null;
});

watch(() => Game.movementInProgress, (__, oldValue) => {
  if (oldValue) {
    videoClass.value = '-fade-out';
    window.setTimeout(() => videoClass.value = undefined, regionEffectTimeout);
  }
});

function bootGame() {
  api.get('game/boot');
}

function updateNow() {
  Game.now = Game.timeDiff + Date.now();
}
</script>

<style>
#game-holder button {
  background-color: var(--button);
  font-weight: bold;
  font-size: inherit;
  white-space: nowrap;
  padding: 1px;
  border-radius: 5px;
  border-color: var(--color-b-74-b2);
  line-height: normal;
}

#game-holder button:disabled {
  background-color: var(--button-disabled);
}

.-fc {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
}

.-fi {
  flex: 1 1 100%;
}

.-fi-narrow {
  flex: 0.1 1 1%;
}

.-rowlike {
  background: var(--list-background);
  box-shadow: inset 0 0 7px 4px var(--list-shadow);
  opacity: 0.9;
  color: var(--text);
}

@media screen and (max-width: 300px) {
  .-fc {
    justify-content: center;
    flex-wrap: wrap;
  }

  .-fi {
    flex: 0 0 100%;
    justify-content: center;
  }
}

.-disabled {
  filter: grayscale(1);
}

.-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.-content-only {
  display: contents;
}

@media screen and (max-width: 540px) {
  :root {
    font-size: 0.8em;
  }

  .-rowlike {
    margin: 3px;
    padding: 5px;
    border-radius: 5px;
  }
}

@media screen and (min-width: 541px) {
  .-rowlike {
    margin: 5px;
    padding: 10px;
    border-radius: 10px;
  }
}

.fullscreen {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -9;
}

.game-background {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.game-background::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.45);
}

.game-background-video {
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
}

.game-background-video.-fade-in {
  animation-name: fade-in;
  animation-duration: 4s;
}

.game-background-video.-fade-out {
  animation-name: fade-out;
  animation-duration: 4s;
}

.animation-glowing {
  animation-name: glow;
}

.animation-fading-in {
  animation-name: fade-in;
}

.-animation-slow {
  animation-duration: 8s;
}

.-animation-fast {
  animation-duration: 3s;
}

.-animation-fastest {
  animation-duration: 1.5s;
}

.-animation-infinite {
  animation-iteration-count: infinite;
}

.-animation-once {
  animation-iteration-count: 1;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes glow {
  0% {
    filter: hue-rotate(0deg) brightness(1);
  }
  50% {
    filter: hue-rotate(359deg) brightness(3);
  }
  70% {
    filter: hue-rotate(0deg) brightness(1);
  }
  100% {
    filter: hue-rotate(0deg) brightness(1);
  }
}
</style>
