<template>
  <Row :image-path="`colonies/${oid}.png`" :labels="labels" row-class="colony"
       :image-classes="imgClasses" :test-identifier="testId" :compact="true">
    <Mutations :list="colony.impact" :root-holder="colonyRoot" display="gain"/>
    <div class="mutations">
      <Mutation :mutation="sizeMutation" display="info"/>
      <Mutation :mutation="regionMutation" :display="regionDisplay"/>
      <Mutation :mutation="ownerMutation" :display="ownerDisplay" concept-display="valueless"/>
    </div>
<!--    todo: move to a separate modal-->
    <div class="-fi">
      <EntityProp type="health" :root-holder="colonyRoot"/>
      <EntityProp type="mp" :root-holder="colonyRoot"/>
      <EntityProp type="rp" :root-holder="colonyRoot"/>
      <EntityProp type="cp" :root-holder="colonyRoot"/>
      <EntityProp type="decks" :root-holder="colonyRoot"/>
    </div>

    <template #buttons>
      <ModalToggler id="targetArtis" display="info" :extra="{targetId: colony._id, targetType: 'ownColony'}"
                    :requires="artiRequirement" v-if="accessibleColony.owned"/>
      <ModalToggler id="ownedModules" display="info" :extra="modulesExtra" v-if="accessibleColony.owned"/>
      <DealButton :action="collect" :deal="collectingDeal" placeholder="collect" v-if="accessibleColony.owned"
                  test-identifier="collect">Collect</DealButton>
      <DealButton :action="colonize" :deal="colonizingDeal" v-if="!accessibleColony.owned"
                  test-identifier="colonize">Colonize</DealButton>
    </template>
  </Row>
</template>

<script setup>
import Mutations from '@/components/utils/Mutations';
import Row from '@/components/utils/Row';
import {computed} from 'vue';
import {Game} from '@/common';
import DealButton from '@/components/utils/DealButton';
import {api} from '@/api';
import {utils} from '@/helpers';
import Mutation from '@/components/utils/Mutation';
import {compositions} from '@/compositions';
import ModalToggler from '@/components/utils/ModalToggler';
import EntityProp from '@/components/utils/EntityProp.vue';

const collectableProps = ['mp', 'cp', 'rp'];

const props = defineProps(
    {
      accessibleColony: {type: Object, required: true},
    },
);

const {ownedColoniesCount, maxColoniesCount} = compositions.colony();

const colonyId = computed(() => props.accessibleColony._id);

const colony = computed(() => Game.colonies[colonyId.value]);

const colonyRoot = computed(() => ({type: 'colony', root: colony.value}));

const oid = computed(() => colony.value.oid);

const sizeMutation = computed(() => ({p1: 'size', val: colony.value.size}));

const regionMutation = computed(() => ({p1: 'regionId', op: 'in', val: [colony.value.regionId]}));

const regionDisplay = computed(() => colony.value.regionId === Game.player.regionId ? 'info' : 'attention');

const ownerMutation = computed(() => ({p1: 'ownership', p2: colony.value.owner ? 'you' : 'nobody', val: ''}));

const ownerDisplay = computed(() => {
  if (!colony.value.owner) {
    return 'gain';
  }
  return colony.value.owner.id === Game.playerId ? 'info' : 'attention';
});

const imgClasses = computed(() => props.accessibleColony.createdAt + 5000 > Date.now() ? // no need to use dynamic Game.now
    ['animation-fading-in', '-animation-fastest', '-animation-once'] :
    undefined
);

const sizeCss = computed(() => (Math.min(120, 20 + 10 * colony.value.size)) + 'px');

const labels = computed(() => ({
  name: colony.value.name,
  descr: Game.getLabels('colonySpecs', oid.value).descr,
}));

const artiRequirement = computed(() => utils.regionOrAnsibleRequirement(colony.value));

const colonizingDeal = computed(() => {
  const deal = utils.clone(Game.gameConf.deals.colonyCapture);
  !deal.requires && (deal.requires = []);
  deal.requires.push(regionMutation.value);
  (ownedColoniesCount.value >= maxColoniesCount.value) && deal.requires.push({p1: 'limit', val: maxColoniesCount.value});
  return deal;
});

const collectingDeal = computed(() => {
  const permitted = collectableProps
      .some(type => {
        const pp = Game.player.props[type];
        const cp = colony.value.props[type];
        return Math.min(pp.max - pp.val, cp.val) > 0;
      });

  return utils.enhanceDealWithRegionOrAnsibleCheck(
      {
        requires: [{p1: 'collect', val: '', permitted}],
      }, colony.value);
});

// NB, must be colony, not colony.value, otherwise reactiveness is lost
const modulesExtra = computed(() => ({rootHolder: {type: 'colony', root: colony}, testIdentifier: colonyId.value}));

const testId = computed(() => `Colony::${colonyId.value}`);

function colonize() {
  api.post(`colonies/${colonyId.value}/colonize`);
}

function collect() {
  api.post(`colonies/${colonyId.value}/collect`);
}
</script>

<style>
.colony .row-image-container {
  display: flex;
  width: 120px;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.colony .row-image {
  background: unset;
  max-width: unset;
  max-height: v-bind(sizeCss);
}

</style>