<template>
  <Row :image-path="`missions/${id}.jpg`" :labels="labels" title-display="full" :test-identifier="testId">
    <DealConcept :title="Game.commonLabels.complete.name" :deal="{gains: props.mission.finishGains}"
                 :root-holder="playerHolder" test-identifier="finishDeal"/>

    <DealConcept :title="withCount('round', props.mission.rounds)"
                 :deal="{gains: props.mission.roundGains}" :cooldown="props.mission.cooldown"
                 :root-holder="playerHolder"  test-identifier="roundDeal"/>

    <DealConcept v-for="(deal, ix) in props.mission.deals"
                 :title="ix === 0 ? withCount('step', props.mission.steps) : ''"
                 :descr="labels.steps ? `#${ix + 1}: ${labels.steps[ix]}` : null"
                 :deal="deal" :key="ix"
                 :root-holder="playerHolder" :test-identifier="'deal' + ix"/>
  </Row>
</template>

<script setup>
import Row from '@/components/utils/Row';
import DealConcept from '@/components/utils/DealConcept';
import {Game, playerHolder} from '@/common';
import {compositions} from '@/compositions';
import {computed} from 'vue';

const props = defineProps(compositions.propDefs.mission);

const { id, labels } = compositions.mission(props);

const testId = computed(() => `MissionConcept::${id.value}`);

function withCount(labelId, count) {
  return `${Game.commonLabels[labelId].name}: ${count ?? '∞'}`;
}
</script>
