<template>
  <Mutation v-show="timeLeft" :display="computedDisplay" :mutation="mutation" :concept-display="conceptDisplay">
    <Clock :target="target" display="off" :text-updater="textUpdater"/>
  </Mutation>
</template>

<script setup>
import Mutation from '@/components/utils/Mutation';
import Clock from '@/components/utils/Clock';
import {computed, ref} from 'vue';

const props = defineProps(
    {
      target: {type: Object, required: true},
      display: {type: String, default: 'info'},
      highlightErrors: {type: Boolean},
      icon: {type: String, default: 'time'},
      conceptDisplay: {type: String},
    }
);

const timeLeft = ref(null);

const mutation = computed(() => ({p1: props.icon, val: timeLeft.value}));

const computedDisplay = computed(() => props.highlightErrors ? 'error' : props.display);

function textUpdater(text) {
  timeLeft.value = text;
}
</script>
