<template>
  <Row :image-path="`icons/artis/${props.id}.svg`" :labels="labels"
       row-class="arti" title-display="full" :test-identifier="testId">

    <template #totals>
      <div v-text="displayAmount"/>
    </template>

    <DealConcept :title="Game.commonLabels.deal.name" :deal="realArti" :mutex-infos="rootMutexInfos" test-identifier="deal"/>

    <DealConcept v-if="realArti.targetDeal" :deal="realArti.targetDeal" :mutex-infos="targetMutexInfos" :display-as-target="true"
                 :root-holder="targetHolder" test-identifier="targetDeal"/>

    <DealConcept :title="Game.commonLabels.info.name" :deal="{}" :cooldown="realArti.cooldown" :research-id="researchId"
                 test-identifier="info"/>

    <DealConcept :title="Game.commonLabels.shreds.name" :deal="{gains: realArti.shreds}"
                 :target-holder="playerHolder" test-identifier="shred"/>
  </Row>
</template>

<script setup>
import Row from '@/components/utils/Row';
import DealConcept from '@/components/utils/DealConcept';
import {Game, playerHolder} from '@/common';
import {compositions} from '@/compositions';
import {computed, toRef} from 'vue';

const props = defineProps(
    {
      ...compositions.propDefs.arti,
      targetHolder: {type: Object, default: null},
    });

const { realArti, labels, displayAmount, researchId, mutexRoot } = compositions.arti(props);

const { rootMutexInfos, targetMutexInfos } = compositions.mux(mutexRoot, playerHolder, toRef(() => props.targetHolder));

const testId = computed(() => `ArtiConcept::${props.id}`);
</script>
