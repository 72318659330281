<template>
  <div v-if="commoditiesPresent" class="list">
    <Commodity v-for="c in commoditiesList" :key="c._id" :commodity="c"/>
  </div>
</template>

<script setup>
import Commodity from '@/components/Commodity';
import {api} from '@/api';
import {Game} from '@/common';
import {computed, onMounted} from 'vue';

const commoditiesList = computed(() => Object.values(Game.commodities));

const commoditiesPresent = computed(() => commoditiesList.value.length);

onMounted(() => !commoditiesPresent.value && loadCommodities());

function loadCommodities() {
  api.get('commodities');
}
</script>
