<template>
  <div style="display: none">
    <Ticker v-for="(prop, key) in Game.player.props" :target="prop" :key="`${Game.player._id}:${key}`"
            :on-fulfil="key === 'health' ? refreshPlayer : undefined"/>
    <Ticker v-for="te in Game.player.temps" :target="te" :on-fulfil="refreshPlayer" :key="JSON.stringify(te)"/>
    <Ticker v-for="ta in Game.player.tasks" :target="ta" :on-fulfil="refreshPlayer" :key="ta._id"/>

    <template v-for="omr in ownedModulesRecords">
      <Ticker v-for="ta in omr.tasks" :target="ta" :on-fulfil="() => loadModulesList(omr)" :key="ta._id"/>
    </template>

    <div v-for="(npc, id) in Game.playerNpcs" :key="id">
      <Ticker v-for="(prop, key) in npc.props" :target="prop" :key="`${id}:${key}`"/>
      <Ticker v-for="te in npc.temps" :target="te" :on-fulfil="() => refreshNpc(id)" :key="JSON.stringify(te)"/>
      <Ticker :target="npc" :on-fulfil="loadNpcsList" :key="id"/>
    </div>

    <template v-for="c in Game.colonies">
      <Ticker v-for="(prop, key) in c.props" :target="prop" :key="`${c._id}:${key}`"/>
    </template>
  </div>
</template>

<script setup>
import Ticker from '@/components/utils/Ticker';
import {api} from '@/api';
import {Game} from '@/common';
import {computed} from 'vue';

const ownedModulesRecords = computed(() => Object.values(Game.ownedModulesRecords));

function refreshPlayer() {
  return api.get('players/current');
}

function loadNpcsList() {
  return api.loadNpcsList();
}

function refreshNpc(id) {
  return api.get(`npcs/${id}`);
}

function loadModulesList(omr) {
  api.loadModulesList(omr.ownerType, omr._id);
}
</script>
