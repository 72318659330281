<template>
  <div v-if="npc" class="list">
    <Row :image-path="imagePath" :image-classes="['avatar']" :labels="labels" title-display="full">
      <Mutations :list="trouble.deal.gains" display="gain"/>

      <template #buttons>
        <DealButton :action="assist" :deal="trouble.deal">Assist</DealButton>
      </template>
    </Row>
  </div>
</template>

<script setup>
import Row from '@/components/utils/Row';
import DealButton from '@/components/utils/DealButton';
import Mutations from '@/components/utils/Mutations';
import {Game, modalsBus} from '@/common';
import {api} from '@/api';
import {computed, watch} from 'vue';

const props = defineProps(
    {
      uid: {type: String, required: true},
    }
);

const npc = computed(() => Game.playerNpcs[props.uid]);

const id = computed(() => npc.value.oid);

const npcObject = computed(() => Game.npcs[id.value]);

const trouble = computed(() => npc.value.trouble);

const imagePath = computed(() => `races/${npcObject.value.raceId}.jpg`);

const labels = computed(() => Game.getLabels('npcTroubles', trouble.value._id));

watch(npc, newValue => !newValue && toggleAssistModal());

function assist() {
  toggleAssistModal();
  api.post(`npcs/${props.uid}/assist`);
}

function toggleAssistModal() {
  modalsBus.emit({id: 'assist'});
}
</script>
