<template>
  <img :alt="visualTitle" :title="visualTitle" :src="require(`@/assets/icons/${path}.svg`)" :class="classes" :data-testid="testId"/>
</template>

<script setup>
import {computed} from 'vue';

const props = defineProps(
    {
      path: {type: String, required: true},
      title: {type: String},
      display: {type: String, validator(v) { return ['gain', 'attention', 'error', 'info'].includes(v) }},
      size: {type: String, validator(v) { return ['large'].includes(v) }},
    }
);

const classes = computed(() => {
  const classes = ['icon'];
  props.display && classes.push('-' + props.display);
  props.size && classes.push('-' + props.size);
  return classes;
});

const visualTitle = computed(() => props.title ?? props.path);

const testId = computed(() => `Icon::${props.path}`);
</script>

<style>
.icon {
  height: 18px;
  width: 18px;
}

.icon.-large {
  height: 50px;
  width: 50px;
}

.icon.-gain {
  filter: drop-shadow(1px 1px 1px var(--gain));
}

.icon.-error {
  filter: drop-shadow(1px 1px 1px var(--error));
}

.icon.-attention {
  filter: drop-shadow(1px 1px 1px var(--attention));
}

.icon.-info {
  filter: drop-shadow(1px 1px 1px var(--info));
}

.-targeted .icon.-gain, .-targeted .icon.-attention {
  filter: drop-shadow(1px 1px 1px var(--targeted-gain));
}

.-targeted .icon.-error {
  filter: drop-shadow(1px 1px 1px var(--targeted-error));
}

.-targeted .icon.-info {
  filter: drop-shadow(1px 1px 1px var(--targeted-info));
}

.title * + .icon {
  margin-left: 3px;
}

@media screen and (max-width: 540px) {
  .icon {
    height: 15px;
    width: 15px;
  }
}

@media screen and (min-width: 300px) and (max-width: 768px) {
  .compact-modal-toggler .icon {
    height: 23px;
    width: 23px;
  }
}
</style>