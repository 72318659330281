<template>
  <Property v-if="showBar" icon="regionId" :prop="movementTask" :show-bar="display === 'linear'" :title="title"
            extra="" :color="['var(--flight-progress-start)', 'var(--flight-progress-end)']"
            time-display="text"/>
  <RadialProgress v-else-if="showCircle" :target="movementTask"
                  start-color="var(--flight-progress-start)" stop-color="var(--flight-progress-end)"
                  :size="95" :stroke-width="6" :inner-stroke-width="4"
                  :hints="{'top-right': 'clock', 'bottom-right': 'valueRemaining', 'top-left': 'max', 'bottom-left': 'value'}"/>
</template>

<script setup>
import Property from '@/components/utils/Property';
import RadialProgress from '@/components/utils/RadialProgress';
import {Game} from '@/common';
import {computed} from 'vue';
import {compositions} from '@/compositions';

const props = defineProps(
    {
      display: {type: String, validator(v) { return ['linear', 'radial', 'text'].includes(v) }, default: 'linear'},
    }
);

const { movementTask, targetRegionId } = compositions.region();

const title = computed(() => {
  const regionId = targetRegionId.value !== undefined ? targetRegionId.value : Game.player.regionId;
  const regionName = Game.getLabels('regions', regionId)?.name ?? '';
  return Game.movementInProgress ? '>> ' + regionName : regionName;
});

const showBar = computed(() => props.display === 'linear' || props.display === 'text');

const showCircle = computed(() => props.display === 'radial');
</script>