<template>
  <div class="modal-container" :style="modalStyles" @click="closeIfMe" ref="rootElement">
    <div v-if="isOpen" class="modal-window">
      <div class="modal-header">
        <span class="modal-close" @click="closeModal">&times;</span>
        <div class="modal-title" v-text="title"/>
        <slot name="header" :extra="slotExtra"/>
      </div>
      <div class="modal-body">
        <slot :extra="slotExtra"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import {Game, modalsBus, storage} from '@/common';
import {computed, ref} from 'vue';
import {TEST_MODE} from '@/main';

const sharedStorage = storage.modals;

const props = defineProps(
    {
      id: {type: String, required: true},
    }
);

const rootElement = ref(null);

const isOpen = ref(false);

const zIndex = ref(null);

const slotExtra = ref(undefined);

const modalsBusOff = ref(undefined);

const modalStyles = computed(() => ({
  'z-index': zIndex.value,
  'display': isOpen.value ? 'block' : 'none',
}));

const isTopmost = computed(() => isOpen.value && zIndex.value === Math.max(...Object.values(sharedStorage.stack)));

const title = computed(() => Game.commonLabels.modals[props.id].name);

modalsBusOff.value = modalsBus.on(e => {
  if (e.all) {
    closeModal();
  }
  else if (e.id === props.id) {
    if (isTopmost.value) {
      closeModal();
    }
    else {
      openModal(e.extra);
    }
  }
});

const effectDuration = TEST_MODE ? '0s' : '0.2s';

window.addEventListener('keydown', escapeListener);

function openModal(extraInfo) {
  zIndex.value = sharedStorage.nextZIndex++;
  isOpen.value = true;
  sharedStorage.stack[props.id] = zIndex.value;
  slotExtra.value = extraInfo;
}

function closeModal() {
  isOpen.value = false;
  delete sharedStorage.stack[props.id];
  slotExtra.value = undefined;
}

function closeIfMe(e) {
  (e.target === rootElement.value) && closeModal();
}

function escapeListener(e) {
  (e.key === 'Escape') && closeModal();
}
</script>

<style>
.modal-container {
  display: none;
  position: fixed;
  left: 0;
  top: var(--top-panel-height);
  right: 0;
  bottom: var(--control-panel-height);
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);

  --shadow-width: 20px;
  --content-top: calc(var(--top-panel-height) + var(--shadow-width));
  --content-left: 10%;
  --content-space: 5px;
  --content-height: calc(98% - var(--content-top) - var(--control-panel-height) - var(--content-space));
  --content-width: calc(100% - 2 * var(--content-left));
}

.modal-window {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  border: 1px solid var(--border);
  border-radius: 8px;
  box-shadow: 0 0 var(--shadow-width) 0 var(--modal-glow);

  position: fixed;
  top: var(--content-top);
  height: var(--content-height);
  left: var(--content-left);
  width: var(--content-width);
  overflow: hidden;

  animation-name: modal-opening;
  animation-duration: v-bind(effectDuration);
}

@keyframes modal-opening {
  from {
    top: calc(100% - var(--control-panel-height));
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
  to {
    top: var(--content-top);
    width: var(--content-width);
    height: var(--content-height);
    opacity: 1;
  }
}

.modal-header {
  flex: 0;
  padding: 1px 6px;
  color: var(--text-invert);
  text-align: center;
}

.modal-header .-rowlike {
  padding: 5px;
}

.modal-title {
  padding: 12px 0 5px 0;
  font-weight: bold;
  font-size: large;
}

.modal-close {
  color: var(--list-background);
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.modal-close:hover, .modal-close:focus {
  color: var(--text-invert);
  text-decoration: none;
  cursor: pointer;
}

.modal-body {
  flex: 1;
  overflow: auto;
}

@media screen and (max-width: 540px) {
  .modal-container {
    --content-left: 2%;
    --content-space: 30px;
  }

  .modal-title {
    padding: 7px 0 1px;
  }

  .modal-close {
    font-size: 45px;
    height: 35px;
    margin-top: -10px;
    margin-right: -2px;
  }
}
</style>