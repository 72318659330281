<template>
  <div v-if="showAnything" :class="classes" :data-testid="testId">
    <div v-if="title" class="title" v-text="title" data-testid="title"/>
    <div v-if="descr" class="descr" v-text="descr" data-testid="description"/>
    <div class="-fc -flex-wrap">
      <div v-if="computedShowRequires">
        <Mutations :list="deal.requires" :research-id="researchId" display="info" :concept-display="conceptDisplay"
                   :root-holder="rootHolder"/>
      </div>
      <div v-if="computedShowUses">
        <Mutations :list="deal.uses" display="info" :concept-display="conceptDisplay"
                   :root-holder="rootHolder"/>
      </div>
      <div v-if="computedShowCosts">
        <Mutations :list="deal.costs" display="attention" :concept-display="conceptDisplay"
                   :root-holder="rootHolder" :target-holder="targetHolder"/>
      </div>
      <div v-if="computedShowGains">
        <Mutations :list="deal.gains" display="gain" :concept-display="conceptDisplay"
                   :root-holder="rootHolder" :target-holder="targetHolder"/>
      </div>
      <div v-if="auxiliaryMutations.length">
        <Mutations :list="auxiliaryMutations" display="info" :concept-display="conceptDisplay"/>
      </div>
      <Mutexes :mutex-infos="mutexInfos"/>
    </div>
  </div>
</template>

<script setup>
import Mutations from '@/components/utils/Mutations';
import {utils} from '@/helpers';
import {computed} from 'vue';
import {compositions} from '@/compositions';
import Mutexes from '@/components/Mutexes';

const props = defineProps(
    {
      title: {type: String},
      descr: {type: String},
      rootHolder: {type: Object},
      targetHolder: {type: Object},
      deal: {type: Object, required: true},
      researchId: {type: String},
      cooldown: {type: Number},
      forgingVal: {type: Number},
      mutexInfos: {type: Array},
      displayAsTarget: {type: Boolean},
      ...compositions.propDefs.testId,
    }
);

const standardClasses = ['concept', '-content-only'];
const targetedClasses = standardClasses.concat('-targeted');

const rootHolder = compositions.rootOrPlayerHolder(props);

const conceptDisplay = computed(() => props.targetHolder === null || !rootHolder.value ? 'valueless' : 'full');

const computedShowRequires = computed(() => utils.hasItems(props.deal.requires) || !!props.researchId);

const computedShowUses = computed(() => utils.hasItems(props.deal.uses));

const computedShowCosts = computed(() => utils.hasItems(props.deal.costs));

const computedShowGains = computed(() => utils.hasItems(props.deal.gains));

const auxiliaryMutations = computed(() => {
  const result = [];
  props.forgingVal && result.push({p1: 'forging', val: props.forgingVal});
  props.cooldown && result.push({p1: 'timed', val: utils.millisToTime(props.cooldown * 1000)});
  return result;
});

const showAnything = computed(() =>
                                  computedShowRequires.value || computedShowUses.value ||
                                  computedShowCosts.value || computedShowGains.value ||
                                  auxiliaryMutations.value.length ||
                                  props.mutexInfos?.length);

const classes = computed(() => props.displayAsTarget ? targetedClasses : standardClasses);

const testId = computed(() => `DealConcept::${props.testIdentifier}`);
</script>

<style>
.concept .title {
  margin-top: 3px;
  font-weight: bold;
}

.concept .descr {
  font-style: italic;
  margin-bottom: 3px;
  line-height: 1rem;
}

@media screen and (max-width: 540px) {
  .concept .descr {
    font-size: 0.9rem;
    line-height: 0.85rem;
  }
}
</style>