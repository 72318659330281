<template>
  <div :class="classes" :data-testid="testId">
    <ClockMutation v-if="progress && progress.to" :target="progress" :display="display"
                   :highlight-errors="highlightErrors" icon="timed" :concept-display="conceptDisplay"/>
    <Mutation v-if="researchId" :mutation="researchMutation" :display="display"
              :highlight-errors="highlightErrors" :concept-display="conceptDisplay"/>
    <Mutation v-for="m in mutationsOfMutexesAtLimit" :mutation="m" :display="display" :highlight-errors="highlightErrors"
              :concept-display="conceptDisplay" :key="JSON.stringify(m)" :root-holder="rootHolder"/>
    <Mutation v-for="mutation in list" :key="JSON.stringify(mutation)" :mutation="mutation" :highlight-errors="highlightErrors"
              :display="display" :concept-display="conceptDisplay" :root-holder="rootHolder" :target-holder="targetHolder"/>
  </div>
</template>

<script setup>
import ClockMutation from '@/components/utils/ClockMutation';
import Mutation from '@/components/utils/Mutation';
import {computed, toRef} from 'vue';
import {utils} from '@/helpers';
import {compositions} from '@/compositions';

const standardClasses = ['mutations', '-flex-wrap'];
const targetedClasses = standardClasses.concat('-targeted');

const props = defineProps(
    {
      list: {type: Array},
      progress: {type: Object},
      researchId: {type: String},
      mutexInfos: {type: Array},
      rootHolder: {type: Object},
      targetHolder: {type: Object},
      display: {type: String},
      highlightErrors: {type: Boolean},
      conceptDisplay: {type: String},
    }
);

const researchMutation = computed(() => ({p1: 'lab', permitted: false, id: props.researchId}));

const { mutexInfosAtLimit } = compositions.mutexesAtLimit(toRef(() => props.mutexInfos));

const mutationsOfMutexesAtLimit = computed(
    () => mutexInfosAtLimit.value.map(m => ({p1: 'limit', val: m.max, permitted: false})));

const classes = computed(
    () => props.targetHolder === undefined || utils.areEqualHolders(props.targetHolder, props.rootHolder) ?
          standardClasses :
          targetedClasses);

const testId = computed(() => `Mutations::${props.display ?? '-'}`);
</script>

<style>
.mutations {
  display: flex;
}

.deal-button-mutations .mutations {
  justify-content: center; /* needed for multiline mutations */
}

@media screen and (max-width: 540px) {
  .deal-button-mutations .mutations {
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 300px) {
  .mutations {
    justify-content: center;
  }
}
</style>