<template>
  <Row :image-path="imagePath" :labels="labels" row-class="commodity" title-display="full" :test-identifier="testId">
    <div class="-fc -flex-wrap" data-testid="commodityDeal">
      <Mutations :list="commodity.gains" display="gain" :root-holder="playerHolder"/>
      <!-- todo: use totals instead of mutexes here -->
    </div>

    <template #buttons>
      <DealButton :action="buy" :deal="commodity" test-identifier="buy">Buy</DealButton>
    </template>
  </Row>
</template>

<script setup>
import {images} from '@/helpers';
import Mutations from '@/components/utils/Mutations';
import DealButton from '@/components/utils/DealButton';
import Row from '@/components/utils/Row';
import {api} from '@/api';
import {Game, playerHolder} from '@/common';
import {computed} from 'vue';

const props = defineProps(
    {
      commodity: {type: Object, required: true},
    }
);

const id = computed(() => props.commodity._id);

const imagePath = computed(() => images.getOrExtract(`commodities/${id.value}.jpg`, props.commodity.gains));

const labels = computed(() => Game.getLabels('commodities', id.value));

const testId = computed(() => `Commodity::${id.value}`);

function buy() {
  api.post(`commodities/${id.value}`);
}
</script>
