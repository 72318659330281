<template>
  <Popup :condition="hasPopup">
    <template #content>
      <Concept v-for="(item, index) in enhancedList" v-bind="item" :no-value="noValue" :key="index"
               :root-holder="rootHolder" :target-holder="targetHolder"/>
    </template>
    <slot/>
  </Popup>
</template>

<script setup>
import Popup from '@/components/utils/Popup';
import Concept from '@/components/utils/Concept';
import {utils} from '@/helpers';
import {Game} from '@/common';
import {computed} from 'vue';
import {compositions} from '@/compositions';

const props = defineProps(
    {
      list: {type: Array, required: true},
      display: {type: String, validator(v) { return ['full', 'valueless', 'off'].includes(v) }, default: 'full'},
      rootHolder: {type: Object},
      targetHolder: {type: Object},
    }
);

const rootHolder = compositions.rootOrPlayerHolder(props);

const hasPopup = computed(() => {
  if (props.display === 'off') {
    return false;
  }
  const hasId = (i) => i.id !== undefined && i.id !== Game.gameConf.ids.inMovementRegion;
  const hasDescription = (i) => i.labels.descr !== undefined;
  return enhancedList.value.some(i => hasId(i) || hasDescription(i));
});

const noValue = computed(() => props.display === 'valueless');

const enhancedList = computed(
    () => props.list.map(i => {
      const clone = utils.clone(i);
      clone.labels = getConceptLabels(clone.parts);
      return clone;
    }));

function getConceptLabels(parts) {
  const commonLabels = Game.commonLabels;
  if (parts?.length) {
    if (commonLabels[parts[2]]) {
      return commonLabels[parts[2]];
    }
    else if (commonLabels[parts[0]]) {
      return utils.traverseSteps(commonLabels, parts);
    }
    else {
      return Game.getLabels(parts[0], parts[1]);
    }
  }
  else {
    return {};
  }
}
</script>
