<template>
  <Row :image-path="`regions/${id}.jpg`" :image-classes="['avatar']"
       :labels="labels" row-class="region" title-display="full" :test-identifier="testId">
    <template #titleExtra>
      <Icon :path="`regions/${id}`" :title="labels.name"/>
    </template>

    <template #imageExtra v-if="shouldShowMovementProgress">
      <MovementBar display="radial"/>
    </template>

    <DealConcept v-if="shouldShowExtra" :deal="movementInfoDeal"/>

    <template #buttons>
      <DealButton v-if="shouldShowExtra" :action="startMovement" :deal="movementDeal">Fly there</DealButton>
    </template>
  </Row>
</template>

<script setup>
import DealButton from '@/components/utils/DealButton';
import DealConcept from '@/components/utils/DealConcept';
import {constants, utils} from '@/helpers';
import Row from '@/components/utils/Row';
import Icon from '@/components/utils/Icon';
import MovementBar from '@/components/utils/MovementBar';
import {Game, modalsBus, regionBus} from '@/common';
import {api} from '@/api';
import {computed} from 'vue';
import {compositions} from '@/compositions';

const props = defineProps(
    {
      region: {type: Object, required: true},
    }
);

const id = computed(() => props.region._id);

const { targetRegionId } = compositions.region();

const currentRegionId = computed(() => Game.player.regionId);

const shouldShowExtra = computed(() => !Game.movementInProgress && currentRegionId.value !== id.value);

const shouldShowMovementProgress = computed(() => Game.movementInProgress && targetRegionId.value === id.value);

const currentRegion = computed(() => Game.regions[currentRegionId.value]);

const distance = computed(
    () => Math.round(Math.sqrt(
        ['x', 'y', 'z']
            .map(a => currentRegion.value[a] - props.region[a])
            .map(d => d * d)
            .reduce((a, b) => a + b, 0),
    )));

const movementTime = computed(() => utils.millisToTime(distance.value / Game.player.props.speed.val * constants.MILLIS_IN_HOUR));

const movementDeal = computed(() => ({
  requires: [{p1: 'flags', p2: Game.gameConf.flags.playerDisabled, op: '!=', val: true}],
  costs: [{p1: 'props', p2: 'energy', p3: 'val', val: distance.value}],
}));

const labels = computed(() => Game.getLabels('regions', id.value));

const movementInfoDeal = computed(() => {
  return {
    uses: [
      {p1: 'distance', val: distance.value + '​'},
      {p1: 'time', val: movementTime.value},
    ],
    gains: [
      {p1: 'xp', val: Math.floor(distance.value * Game.gameConf.multipliers.flightXp) + '​'},
    ],
  };
});

const testId = computed(() => `Region::${id.value}`);

function startMovement() {
  modalsBus.emit({all: true});
  regionBus.emit(currentRegionId.value);
  api.post(`regions/${id.value}`);
}
</script>
