<template>
  <div v-if="isInProgress" class="-fc -flex-wrap">
    <Mutations :list="inProgressMutations" display="info"/>
  </div>
</template>

<script setup>
import Mutations from '@/components/utils/Mutations';
import {compositions} from '@/compositions';

const props = defineProps(
    {
      assignmentId: {type: String, required: true},
    },
);

const { inProgressMutations, isInProgress } = compositions.assignments(props.assignmentId);
</script>
