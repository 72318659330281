<template>
  <div class="progress">
    <div v-if="title !== undefined" class="progress-text progress-title" v-text="title" data-testid="title"/>
    <div class="progress-bar" :style="styles"></div>
    <div v-if="extra !== undefined" class="progress-text progress-extra" v-text="extraText" :title="extraTitle" data-testid="extra"/>
    <div class="progress-text progress-value" v-text="displayText" :title="displayTitle" data-testid="value"/>
  </div>
</template>

<script setup>
import {computed} from 'vue';
import {compositions} from '@/compositions';

const props = defineProps(
    {
      title: {type: String},
      value: {type: Number},
      min: {type: Number, default: 0},
      max: {type: Number},
      extra: undefined,
      displayValue: undefined,
      color: {type: [String, Array]},
      ...compositions.propDefs.numbers,
    }
);

const { formatNumbers } = compositions.numbers(props);

const percentage = computed(() => props.max ? Math.min(100, 100 * (props.value - props.min) / (props.max - props.min)) : 0);

const displayText = computed(() => getDisplay());

const displayTitle = computed(() => getDisplay('full'));

const extraText = computed(() => getExtra());

const extraTitle = computed(() => getExtra('full'));

const styles = computed(() => {
  const styles = {};
  styles.width = percentage.value + '%';
  if (Array.isArray(props.color)) {
    const multiplier = percentage.value ? (100 * 100 / percentage.value / (props.color.length - 1)) : 0;
    const colorString = props.color.map((color, index) => `${color} ${index * multiplier}%`).join(', ');
    styles['background-image'] = `linear-gradient(to right, ${colorString})`;
  }
  else if (props.color) {
    styles['background-color'] = props.color;
  }
  return styles;
});

function getDisplay(format = undefined) {
  const getValues = () => {
    if (props.displayValue !== undefined) {
      return props.displayValue;
    }
    else if (props.max && !props.min) {
      return [props.value, ' / ', props.max];
    }
    else {
      return props.value;
    }
  }
  return formatNumbers(getValues(), format);
}

function getExtra(format = undefined) {
  return formatNumbers(props.extra, format);
}
</script>

<style>
.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1.2rem;
  background-color: var(--text-invert);
  border-radius: 3px;
  margin-top: 5px;
  position: relative;
}

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  transition: width 70ms ease;
  border-radius: 3px;
  box-shadow: inset 0 0 4px 1px var(--bar-shadow);
}

.progress-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
  color: var(--text);
  font-size: small;
}

.progress-title {
  margin-left: 2px;
}

.progress-value {
  right: 0;
  margin-right: 2px;
}

.progress-extra {
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 540px) {
  .progress-text {
    font-size: 0.9rem;
  }

  .progress {
    margin: 4px 0 0 0;
  }

  .-narrower .progress-title {
    display: none;
  }

  .-narrower .progress-extra {
    margin-left: 7px;
    text-align: left;
  }
}

@media screen and (max-width: 300px) {
  .progress-text {
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 300px) {
  .progress-value {
    margin-left: 7px;
    text-align: left;
  }
}
</style>
