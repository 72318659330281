<template>
  <div v-if="isVisible">
    <span v-text="displayText"/>
  </div>
</template>

<script setup>
import {utils} from '@/helpers';
import {Game} from '@/common';
import {computed, onMounted} from 'vue';

const props = defineProps(
    {
      target: {type: Object, required: true},
      display: {type: String, validator(v) { return ['text', 'off'].includes(v) }, default: 'text'},
      valueUpdater: {type: Function},
      textUpdater: {type: Function},
    }
);

const isPassed = computed(() => props.target.to < Game.now);

const isVisible = computed(() => props.display !== 'off' && !isPassed.value);

const timeLeft = computed(() => isPassed.value ? null : props.target.to - Game.now);

const displayText = computed(() => isPassed.value ? null : utils.millisToTime(timeLeft.value));

onMounted(() => {
  if (props.valueUpdater) {
    utils.watchAndTrigger(timeLeft, () => props.valueUpdater(timeLeft.value));
  }
  if (props.textUpdater) {
    utils.watchAndTrigger(displayText, () => props.textUpdater(displayText.value));
  }
});

</script>
