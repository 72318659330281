<template>
  <div class="-rowlike legal-content">
    <div class="legal-link">
      <a href="/policy.html" target="_blank">Privacy Policy</a>
    </div>
    <div class="legal-link">
      <a href="/terms.html" target="_blank">Terms of Service</a>
    </div>
  </div>
</template>

<style>
.legal-content .legal-link {
  line-height: 1.5;
}
</style>
