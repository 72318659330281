<template>
  <Row v-if="inSameRegion" :image-path="`npcs/${id}.png`"
       :labels="labels" row-class="npc" :compact="true" :test-identifier="testId">
    <template #titleExtra>
      <Icon :path="`races/${npcObject.raceId}`" :title="Game.getLabels('races', npcObject.raceId).name"/>
    </template>

    <template #imageExtra>
      <RadialProgress :target="npc.props.shields"
                      :clockwise="false"
                      start-color="var(--shields)" stop-color="var(--shields)"
                      :size="83" :stroke-width="4" :inner-stroke-width="1.5"
                      :percent="false" :hints="{'bottom-left': 'valueOfMax'}"
      />
      <RadialProgress :target="npc.props.health"
                      :clockwise="false"
                      start-color="var(--health)" stop-color="var(--health)"
                      :size="75" :stroke-width="4" :inner-stroke-width="1.5"
                      :percent="totalHealth" :hints="{'bottom-right': 'valueOfMax'}"
      />
    </template>

    <div class="mutations">
      <ClockMutation :target="{to: npc.to, duration: totalTime}" icon="time" display="attention"/>
    </div>

    <template #buttons>
      <ModalToggler id="targetArtis" display="info" :extra="{targetId: npc._id, targetType: 'npc'}"/>
      <DealButton :action="attack" :deal="npcAttack">Attack</DealButton>
      <ModalToggler v-if="npc.trouble" id="assist" display="info" :extra="npc._id"/>
    </template>
  </Row>
</template>

<script setup>
import DealButton from '@/components/utils/DealButton';
import Icon from '@/components/utils/Icon';
import Row from '@/components/utils/Row';
import ModalToggler from '@/components/utils/ModalToggler';
import RadialProgress from '@/components/utils/RadialProgress';
import ClockMutation from '@/components/utils/ClockMutation';
import {api} from '@/api';
import {Game} from '@/common';
import {computed} from 'vue';

const props = defineProps(
    {
      npc: {type: Object, required: true},
    },
);

const id = computed(() => props.npc.oid);

const uid = computed(() => props.npc._id);

const npcObject = computed(() => Game.npcs[id.value]);

const npcAttack = computed(() => Game.gameConf.deals.npcAttack);

const inSameRegion = computed(() => Game.player.regionId === props.npc.regionId);

const totalTime = computed(() => npcObject.value.duration * 1000);

const labels = computed(() => Game.getLabels('npcs', id.value));

const totalHealth = computed(() => {
  const health = props.npc.props.health;
  const shields = props.npc.props.shields ?? {val: 0, max: 0};
  return (health.val + shields.val) / (health.max + shields.max);
});

const testId = computed(() => `Npc::${uid.value}`);

function attack() {
  api.post(`npcs/${uid.value}/attack`);
}
</script>

<style>
.npc .row-image {
  max-width: 150px;
  background: unset;
}

.npc .row-image-container {
  width: 150px;
}
</style>