<template>
  <div v-if="artisPresent" class="list">
    <Artifact v-for="(arti, id) in filteredArtis" :key="id" :arti="arti" :id="id"
              :target-id="props.targetId" :target-type="props.targetType"/>
  </div>
</template>

<script setup>
import Artifact from '@/components/Artifact';
import {utils} from '@/helpers';
import {api} from '@/api';
import {Game} from '@/common';
import {computed} from 'vue';
import {compositions} from '@/compositions';

const props = defineProps(compositions.propDefs.target);

const artisPresent = computed(() => utils.allKeysPresent(Game.artis, Object.keys(Game.player.artis)));

const filteredArtis = computed(() => {
  return Object.entries(Game.player.artis)
      .filter(([id, entry]) => entry.val && (!props.targetType || (props.targetType === Game.artis[id]?.targetType)))
      .reduce((result, [id, entry]) => {
        result[id] = entry;
        return result;
      }, {});
});

const filteredPlayerArtiIds = computed(() => Object.keys(filteredArtis.value));

const relatedResearchIds = computed(() => {
  return artisPresent.value ?
         filteredPlayerArtiIds.value
             .map(id => Game.artis[id].researchId)
             .filter(Boolean) :
      [];
});

utils.watchAndTrigger(artisPresent, () => !artisPresent.value && api.get('artis'));

compositions.researchesLoader(relatedResearchIds);
</script>
