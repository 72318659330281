<template>
  <div class="list">
    <Research v-for="r in filteredResearches" :key="`r_${r._id}`" :research="r"/>
    <template v-if="canDonateResearch">
      <Race v-for="race in Game.races" :race="race" :key="`d_${race._id}`"/>
    </template>
  </div>
</template>

<script setup>
import Research from '@/components/Research';
import Race from '@/components/Race';
import {api} from '@/api';
import {utils} from '@/helpers';
import {Game} from '@/common';
import {computed, onMounted} from 'vue';

onMounted(() => api.loadResearchProgresses());

const filteredResearches = computed(() => {
  const unfinishedResearchesIds = Object.entries(Game.playerResearches)
      .filter(([, pr]) => !pr.fin)
      .map(([id]) => id);
  return Object.values(Game.researches)
      .filter(r => unfinishedResearchesIds.includes(r._id));
});

const canDonateResearch = computed(() => utils.hasTalent(Game.gameConf.researchDonation.talentId));
</script>
