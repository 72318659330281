<template>
  <Concepts :list="[{parts: parts}]" :root-holder="rootHolder">
    <Property :icon="icon" :icon-display="iconDisplay" :prop="prop" :title="labels.name" :color="conf.color"
              :number-format="conf.numberFormat" :extra="computedExtra"/>
  </Concepts>
</template>

<script setup>
import Concepts from '@/components/utils/Concepts';
import Property from '@/components/utils/Property';
import {constants, utils} from '@/helpers';
import {Game} from '@/common';
import {computed} from 'vue';
import {compositions} from '@/compositions';

const props = defineProps(
    {
      type: {type: String, validator(v) { return Object.keys(constants.PROPERTY_CONFS).includes(v) }, required: true},
      iconDisplay: {type: String},
      rootHolder: {type: Object},
    }
);

const rootHolder = compositions.rootOrPlayerHolder(props);

const { totalAssignedEngineersCount } = compositions.crew();

const conf = computed(() => constants.PROPERTY_CONFS[props.type]);

const parts = computed(() => [conf.value.prop, props.type].filter(Boolean));

const icon = computed(() => parts.value.join('/'));

const labels = computed(() => utils.traverseSteps(Game.commonLabels, parts.value));

const prop = computed(() => {
  if (props.type === 'level') {
    return {val: rootHolder.value.root.level};
  }
  const p = utils.traverseSteps(rootHolder.value.root, parts.value);
  if (props.type === 'engineers') {
    return {val: p.val - totalAssignedEngineersCount.value, max: p.val};
  }
  return p;
});

const computedExtra = computed(() => conf.value.extra?.(prop.value));
</script>
