<template>
  <div class="list">
    <Mission v-for="m in filteredMissions" :key="m._id" :mission="m"/>
  </div>
</template>

<script setup>
import Mission from '@/components/Mission';
import {api} from '@/api';
import {Game} from '@/common';
import {computed, onMounted} from 'vue';

onMounted(() => api.get('missions'));

const filteredMissions = computed(() => {
  const missionIds = Object.entries(Game.playerMissions)
      .filter(([, pm]) => !pm.fin)
      .map(([id,]) => id);
  return Object.values(Game.missions)
      .filter(r => missionIds.includes(r._id));
});
</script>
