<template>
  <div class="-rowlike -fc user-settings">
    <div class="-fi">
      <DealButton :deal="{}" placeholder="logout" usage-display="attention" :action="doSignOut">Sign out</DealButton>
    </div>
    <div class="-fi delete-player">
      <DealButton :deal="{}" placeholder="delete" usage-display="error" :action="removePlayer">Delete player</DealButton>
    </div>
  </div>

</template>

<script setup>
import DealButton from '@/components/utils/DealButton';
import {signOut} from 'aws-amplify/auth';
import {api} from '@/api';

function removePlayer() {
  if (confirm('Really delete user? It cannot be undone!')) {
    api.del('players/current')
        .then(() => doSignOut());
  }
}

function doSignOut() {
  signOut()
      .then(() => window.location.reload());
}
</script>

<style>
.user-settings .delete-player {
  text-align: right;
}
</style>