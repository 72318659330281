<template>
  <Row :image-path="`races/${id}.jpg`" :image-classes="['avatar']" :labels="labels"
       row-class="race" title-display="full" :compact="true">
    <template #titleExtra>
      <Icon :path="`races/${id}`" :title="labels.name"/>
    </template>

    <div class="-fc -flex-wrap race-relations">
      <div class="-fi" v-text="alliesString"/>
      <div class="-fi" v-text="enemiesString"/>
    </div>

    <Property icon="relations" :title="Game.commonLabels.relations.name" :prop="{val: relation, min: -10, max: 10}"
              :color="['var(--enemy)', 'var(--harmless)', 'var(--ally)']"/>

    <template #buttons>
      <DealButton v-if="canDonateResearch" :action="doDonate" :deal="donationDeal">Donate</DealButton>
    </template>
  </Row>
</template>

<script setup>
import Property from '@/components/utils/Property';
import Icon from '@/components/utils/Icon';
import Row from '@/components/utils/Row';
import DealButton from '@/components/utils/DealButton';
import {api} from '@/api';
import {utils} from '@/helpers';
import {Game} from '@/common';
import {computed} from 'vue';

const props = defineProps(
    {
      race: {type: Object, required: true},
    },
);

const id = computed(() => props.race._id);

const relation = computed(() => Game.player.relations[id.value] ?? 0);

const labels = computed(() => Game.getLabels('races', id.value));

const enemiesString = computed(() => `${Game.commonLabels.enemies.name}: ${raceNamesString(props.race.enemies)}`);

const alliesString = computed(() => `${Game.commonLabels.allies.name}: ${raceNamesString(props.race.allies)}`);

const donationDeal = computed(() => {
  const granule = Game.gameConf.researchDonation.granule;
  const times = Math.max(Math.floor(Game.player.props.rp.val / granule), 1);
  return {
    requires: props.race.researchDonationRequirement,
    costs: [{p1: 'props', p2: 'rp', p3: 'val', val: times * granule}]
  };
});

const canDonateResearch = computed(() => utils.hasTalent(Game.gameConf.researchDonation.talentId));

function raceNamesString(ids) {
  return ids.map(id => Game.getLabels('races', id).name).join(', ');
}

function doDonate() {
  api.post(`researches/donate/${id.value}`);
}
</script>

<style>
.race .race-relations {
  margin-top: 4px;
  font-weight: bold;
}

.race .row-content-container {
  width: 100%;
}
</style>