<template>
  <Property title="XP" icon="xp" :prop="prop" :display-value="xp" :extra="xp - max" color="var(--xp)"/>
</template>

<script setup>
import Property from '@/components/utils/Property';
import {Game} from '@/common';
import {computed} from 'vue';

const xp = computed(() => Game.player.xp);

const max = computed(() => levelUpXp(Game.player.level));

const prop = computed(() => {
  const start = levelUpXp(Game.player.level - 1);
  return {
    val: xp.value - start,
    max: max.value - start
  };
});

function levelUpXp(level) {
  return level * level * Game.gameConf.consts.xpMultiplier;
}
</script>