<template>
  <Mutations v-if="mutexesList.length" :list="mutexesList" display="info"/>
</template>

<script setup>
import Mutations from '@/components/utils/Mutations';
import {computed} from 'vue';

const props = defineProps(
    {
      mutexInfos: {type: Array, default: () => []},
    }
);

const mutexesList = computed(() => props.mutexInfos.map(i => ({p1: 'limit', val: i.text})));
</script>