<template>
  <Dropdown v-if="condition" container="#game-holder" placement="bottom"
            :handle-resize="true" @show="isOpen=true" @hide="isOpen=false">
    <div style="cursor: pointer">
      <slot/>
    </div>
    <template #popper>
      <div>
        <slot name="content" v-if="isOpen"/>
      </div>
    </template>
  </Dropdown>

  <div v-else class="-content-only">
    <slot/>
  </div>
</template>

<script setup>
import {Dropdown} from 'floating-vue';
import 'floating-vue/dist/style.css';
import {ref} from 'vue';

const props = defineProps(
    {
      condition: {type: Boolean, default: true},
    }
);

const isOpen = ref(false);
</script>

<style>
.v-popper__popper {
  --popper-shadow-width: 15px;
  --popper-arrow-color: var(--border);

  max-width: 80%;
  max-height: 65%;
  width: auto;
}

.v-popper--theme-dropdown .v-popper__inner {
  background: rgba(0, 0, 0, 0.7);
  color: var(--text-invert);
  border: 1px solid var(--border);
  -moz-box-shadow: 0 0 var(--popper-shadow-width) 0 var(--modal-glow);
  -webkit-box-shadow: 0 0 var(--popper-shadow-width) 0 var(--modal-glow);
  box-shadow: 0 0 var(--popper-shadow-width) 0 var(--modal-glow);
}

.v-popper--theme-dropdown .v-popper__arrow-inner {
  display: none;
}

.v-popper--theme-dropdown .v-popper__arrow-outer {
  border-color: var(--popper-arrow-color);
}
</style>
