<template>
  <div v-if="modulesPresent" class="list">
    <Module v-for="module in ownedModules" :key="module._id" :module="module" :root-holder="rootHolder"/>
  </div>
</template>

<script setup>
import Module from '@/components/Module';
import {utils} from '@/helpers';
import {Game} from '@/common';
import {api} from '@/api';
import {computed, onMounted} from 'vue';
import {compositions} from '@/compositions';

const props = defineProps(
    {
      rootHolder: {type: Object},
    });

const rootHolder = compositions.rootOrPlayerHolder(props);

onMounted(() => api.loadModulesList(rootHolder.value.type, rootHolder.value.root._id));

const { modulesRecord } = compositions.modules(rootHolder.value.root._id);

const ownedModules = computed(() => {
  const modules = modulesRecord.value?.modules ?? [];
  return utils.sortBy(modules, 'oid', true, true);
});

const allOwnedModuleOids = computed(() => Array.from(new Set(ownedModules.value.map(pm => pm.oid))));

const modulesPresent = computed(() => utils.allKeysPresent(Game.modules, allOwnedModuleOids.value));

const relatedResearchIds = computed(() => {
  return modulesPresent.value ?
         allOwnedModuleOids.value
             .flatMap(id => {
               const module = Game.modules[id];
               return [module.researchId, module.ability?.researchId];
             })
             .filter(Boolean) :
      [];
});

compositions.researchesLoader(relatedResearchIds);
</script>
