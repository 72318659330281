import {createApp} from 'vue';
import GameWrapper from '@/components/GameWrapper';
import {Amplify} from 'aws-amplify'
import config from './amplifyconfiguration.json';

export const TEST_MODE = process.env.VUE_APP_TEST_MODE === 'true';
TEST_MODE && console.info('Running in Test Mode');

Amplify.configure(config);

const app = createApp(GameWrapper);

app.mount('#game-root');
