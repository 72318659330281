<!-- todo: move to compositions -->
<template>
  <span/>
</template>

<script setup>
import {constants} from '@/helpers';
import {computed, ref, watch} from 'vue';
import {Game} from '@/common';

const props = defineProps(
    {
      target: {type: Object, required: true},
      onFulfil: {type: Function},
    }
);

const canTrigger = ref(true);

const isTimed = computed(() => props.target.to);

watch(() => Game.now, newValue => {
  if (isTimed.value) {
    checkFulfilment(newValue);
  }
  else {
    updateTarget(newValue);
  }
});

function updateTarget(now) {
  const t = props.target;
  if (t.uph === 0) {
    return;
  }
  if (t.max === undefined || t.uph === undefined || t.at === undefined) {
    return;
  }
  let increase = (now - t.at) * t.uph / constants.MILLIS_IN_HOUR;
  if (t.float) {
    t.val += increase;
    t.at = now;
  }
  else {
    increase = Math.min(Math.trunc(increase), t.max - t.val);
    if (increase > 0) {
      t.val += increase;
      t.at += increase * constants.MILLIS_IN_HOUR / t.uph;
    }
  }

  if (t.val >= t.max) {
    t.val = t.max;
    if (canTrigger.value && props.onFulfil) {
      fireOnFulfil();
    }
  }
  else {
    canTrigger.value = true;
  }
}

function checkFulfilment(now) {
  if (canTrigger.value && now >= props.target.to) {
    fireOnFulfil();
  }
}

function fireOnFulfil() {
  canTrigger.value = false;
  props.onFulfil();
}
</script>
