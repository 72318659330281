<template>
  <Row :image-path="`missions/${id}.jpg`" :labels="labels" :test-identifier="testId">
    <template #imageExtra>
      <RadialProgress :target="{val: currentRound, max: props.mission.rounds}"
                      start-color="var(--progress-2)" stop-color="var(--progress-2)"
                      :show-at-zero="true" :always-show-hints="true" :percent="progressPercent || false"
                      :size="83" :stroke-width="6" :inner-stroke-width="3"
                      :hints="{'bottom-left': props.mission.rounds ? 'valueOfMax' : 'value'}"
      />
      <RadialProgress :target="{val: currentStep, max: props.mission.steps}"
                      start-color="var(--progress-1)" stop-color="var(--progress-1)"
                      :show-at-zero="true" :percent="false"
                      :size="70" :stroke-width="6" :inner-stroke-width="3"
                      :hints="{'bottom-right': 'valueOfMax'}"
      />
    </template>

    <template #titlePopupContent>
      <MissionConcept :mission="props.mission"/>
    </template>

    <div class="-fc -flex-wrap" data-testid="missionGains">
      <Mutations :list="nextClickGains" display="gain" :root-holder="playerHolder"/>
    </div>

    <template #buttons>
      <DealButton v-if="canRevert" :action="revertMission" :deal="revertDeal" test-identifier="revert">Revert</DealButton>
      <DealButton :action="performMission" :deal="currentStepDeal" :progress="progress" test-identifier="perform">Do it</DealButton>
    </template>
  </Row>
</template>

<script setup>
import Row from '@/components/utils/Row';
import Mutations from '@/components/utils/Mutations';
import DealButton from '@/components/utils/DealButton';
import RadialProgress from '@/components/utils/RadialProgress';
import MissionConcept from '@/components/MissionConcept';
import {api} from '@/api';
import {Game, playerHolder} from '@/common';
import {computed} from 'vue';

import {compositions} from '@/compositions';

const props = defineProps(compositions.propDefs.mission);

const { id, labels } = compositions.mission(props);

const progress = computed(() => Game.playerMissions[id.value] ?? {val: 0});

const progressVal = computed(() => progress.value.val);

const currentStep = computed(() => progressVal.value % props.mission.steps);

const currentRound = computed(() => Math.floor(progressVal.value / props.mission.steps));

const totalSteps = computed(() => props.mission.rounds && (props.mission.rounds * props.mission.steps));

const progressPercent = computed(() => totalSteps.value && (progressVal.value / totalSteps.value));

const currentStepDeal = computed(() => {
  const deals = props.mission.deals;
  return deals[progressVal.value % deals.length];
});

const nextClickGains = computed(() => {
  const result = [...currentStepDeal.value.gains];

  const nextCount = progressVal.value + 1;
  if (props.mission.roundGains && nextCount % props.mission.steps === 0) {
    result.push(...props.mission.roundGains);

    if (totalSteps.value &&
        props.mission.finishGains &&
        nextCount === totalSteps.value) {
      result.push(...props.mission.finishGains);
    }
  }
  return result;
});

const canRevert = computed(() => props.mission.revertCosts && (progressVal.value % props.mission.steps !== 0));

const revertDeal = computed(() => ({ costs: props.mission.revertCosts }));

const testId = computed(() => `Mission::${id.value}`);

function performMission() {
  api.post(`missions/${id.value}`);
}

function revertMission() {
  api.del(`missions/${id.value}`);
}
</script>
