<template>
  <div :class="panelClasses">
    <div class="top-bars">
      <div v-if="closestEffect" class="bar-item">
        <Property icon="effect" icon-display="info" :prop="{to: closestEffect}"
                  :show-bar="false" :title="Game.commonLabels.effect.name"/>
      </div>
      <div v-if="closestNpc" class="bar-item">
        <Property icon="npc" icon-display="info" :prop="{to: closestNpc}"
                  :show-bar="false" :title="Game.commonLabels.npc.name"/>
      </div>
      <div v-if="closestForgingTask" class="bar-item">
        <Property icon="forging" icon-display="info" :prop="closestForgingTask"
                  :show-bar="false" time-display="text" :title="Game.commonLabels.forging.name"/>
      </div>
      <div v-if="Game.movementInProgress" class="bar-item">
        <MovementBar display="text"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import MovementBar from '@/components/utils/MovementBar';
import {constants} from '@/helpers';
import Property from '@/components/utils/Property';
import {Game} from '@/common';
import {computed, ref, watch} from 'vue';

const wasOpen = ref(false);

const closestEffect = computed(() => getClosest(Game.player.temps));

const closestNpc = computed(() => getClosest(Object.values(Game.playerNpcs)));

const closestForgingTask = computed(() => {
  const tasks = Object.values(Game.ownedModulesRecords).flatMap(r => r.tasks);
  if (!tasks?.length) {
    return undefined;
  }
  const activeTasks = tasks.filter(t => t.uph > 0);
  if (!activeTasks.length) {
    return undefined;
  }
  const minMillisLeft = Math.min(...activeTasks.map(millisLeftForProperty));
  return activeTasks.find(t => millisLeftForProperty(t) === minMillisLeft);
});

const isOpen = computed(() => {
  return closestEffect.value ||
         closestNpc.value ||
         closestForgingTask.value ||
         Game.movementInProgress;
});

const panelClasses = computed(() => {
  const result = ['top-panel'];
  if (isOpen.value) {
    result.push('-open')
  }
  else if (wasOpen.value) {
    result.push('-closed')
  }
  return result;
});

watch(isOpen, newValue => newValue && (wasOpen.value = true));

function getClosest(root) {
  return root?.length ? Math.min(...root.map(e => e.to)) : undefined;
}

function millisLeftForProperty(t) {
  return constants.MILLIS_IN_HOUR * (t.max - t.val) / t.uph;
}
</script>

<style>
:root {
  --top-panel-height: 2rem;
}

.top-panel {
  --top-panel-top: 0;
  --top-panel-closed-top: -20px;
  --top-panel-opacity: 1;
  --top-panel-closed-opacity: 0.7;

  position: fixed;
  top: var(--top-panel-closed-top);
  width: 100%;
  height: var(--top-panel-height);
  background: var(--list-background);
  box-shadow: inset 0 0 7px 4px var(--list-shadow);
  border-radius: 0 0 15px 15px;
  opacity: var(--top-panel-closed-opacity);
}

.top-panel.-open {
  top: var(--top-panel-top);
  opacity: var(--top-panel-opacity);
  animation-name: top-panel-opening;
  animation-duration: 0.3s;
}

.top-panel.-closed {
  top: var(--top-panel-closed-top);
  opacity: var(--top-panel-closed-opacity);
  animation-name: top-panel-closing;
  animation-duration: 0.3s;
}

@keyframes top-panel-opening {
  from {
    top: var(--top-panel-closed-top);
    opacity: var(--top-panel-closed-opacity);
  }
  to {
    top: var(--top-panel-top);
    opacity: var(--top-panel-opacity);
  }
}

@keyframes top-panel-closing {
  from {
    top: var(--top-panel-top);
    opacity: var(--top-panel-opacity);
  }
  to {
    top: var(--top-panel-closed-top);
    opacity: var(--top-panel-closed-opacity);
  }
}

.top-bars {
  display: flex;
  justify-content: flex-end;
  margin: 1px 10px 0;
}

.bar-item {
  flex: 0 1 1%;
  margin: 1px 0 0 10px;
  white-space: nowrap;
}
</style>
