<template>
  <TimerClock  v-if="isTimed" :target="target" :display="display" :value-updater="valueUpdater" :text-updater="textUpdater"/>
  <TickerClock v-else         :target="target" :display="display" :value-updater="valueUpdater" :text-updater="textUpdater"/>
</template>

<script setup>
import TimerClock from '@/components/utils/TimerClock';
import TickerClock from '@/components/utils/TickerClock';
import {computed} from 'vue';

const props = defineProps(
    {
      target: {type: Object, required: true},
      display: {type: String},
      valueUpdater: {type: Function},
      textUpdater: {type: Function},
    }
);

const isTimed = computed(() => props.target.to);

</script>
