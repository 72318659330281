<template>
  <div class="-rowlike -fc" :data-testid="testId">
    <div class="-fi">
      <EntityProp type="engineers"/>
    </div>
    <div class="-fi">
      <EntityProp type="decks" :root-holder="rootHolder"/>
    </div>
  </div>
</template>

<script setup>
import EntityProp from '@/components/utils/EntityProp';
import {compositions} from '@/compositions';
import {computed} from 'vue';

const props = defineProps(
    {
      rootHolder: {type: Object},
      ...compositions.propDefs.testId,
    });

const testId = computed(() => `ModulesHeader::${props.testIdentifier}`);
</script>
