<template>
  <DealButton :deal="deal" :action="toggleModal" :usage-display="isCompact ? null : display"
              :placeholder="{p1: 'modals', p2: id, val: ''}"
              :classes="isCompact ? ['compact-modal-toggler'] : null" :test-identifier="testId">
    <span :class="isCompact ? 'compact-modal-toggler-title' : null" v-text="labels.name"/>
  </DealButton>
</template>

<script setup>
import DealButton from '@/components/utils/DealButton';
import {Game, modalsBus} from '@/common';
import {computed} from 'vue';

const props = defineProps(
    {
      id: {type: String, required: true},
      display: {type: String, validator(v) { return ['compact', 'info'].includes(v) }, default: 'compact'},
      requires: {type: Array, default: () => []},
      extra: {default: undefined},
    }
);

const labels = computed(() => Game.commonLabels.modals[props.id] ?? {});

const deal = computed(() => ({ requires: props.requires }));

const isCompact = computed(() => props.display === 'compact');

const testId = computed(() => `ModalToggler::${props.id}`);

function toggleModal() {
  modalsBus.emit({id: props.id, extra: props.extra});
}
</script>

<style>
.compact-modal-toggler .mutation {
  border: unset;
  background-color: unset;
}

@media screen and (max-width: 768px) {
  .compact-modal-toggler-title {
    display: none;
  }
}
</style>