<template>
  <div class="list">
    <Npc v-for="npc in npcsList" :key="npc._id" :npc="npc"/>
  </div>
</template>

<script setup>
import Npc from '@/components/Npc';
import {api} from '@/api';
import {computed, onMounted} from 'vue';
import {Game} from '@/common';
import {utils} from '@/helpers';

const npcsList = computed(() => utils.sortBy(Object.values(Game.playerNpcs), 'createdAt', true));

onMounted(() => api.loadNpcsList());
</script>
