<template>
  <div class="-rowlike -fc" style="align-items: center; column-gap: 3px;">
    <div class="-fi">
      <EntityProp type="scan"/>
      <Property icon="modals/colonies" :prop="{val: ownedColoniesCount, max: maxColoniesCount}"
                :title="Game.labels.commons.modals.colonies.name" color="var(--colonies)"
                number-format="full" :extra="accessibleColoniesList.length"/>
    </div>
    <div class="-fi-narrow">
      <DealButton :action="seek" :deal="Game.gameConf.deals.colonySeek" :show-gains="true">Seek more</DealButton>
    </div>
  </div>
</template>

<script setup>
import DealButton from '@/components/utils/DealButton';
import {api} from '@/api';
import {Game} from '@/common';
import EntityProp from '@/components/utils/EntityProp';
import Property from '@/components/utils/Property';
import {compositions} from '@/compositions';

const { accessibleColoniesList, ownedColoniesCount, maxColoniesCount } = compositions.colony();

function seek() {
  api.post('colonies/seek');
}
</script>
