<template>
  <div class="-rowlike -fc" data-testid="NpcsHeader">
    <div class="-fi">
      <DealButton :action="seek" :deal="Game.gameConf.deals.npcSeek" :show-gains="true"
                  test-identifier="npcSeek">Seek more</DealButton>
    </div>
  </div>
</template>

<script setup>
import DealButton from '@/components/utils/DealButton';
import {api} from '@/api';
import {Game} from '@/common';

function seek() {
  api.post('npcs/seek');
}
</script>
